import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { styled } from '@mui/material';
import {
  Map,
  MapProps,
  APIProvider as GoogleMapApiProvider,
  MapCameraProps,
  MapCameraChangedEvent,
  ControlPosition,
} from '@vis.gl/react-google-maps';

import { defaultGoogleMap, googleMapId, googleMapsApiKey } from '@/config';

import { IMarker, MapMarker } from './MapMarker';
import { ZoomControl } from './ZoomControl';

const MapRoot = styled('div')(() => ({
  '& .gm-style-iw-chr': {
    display: 'none',
  },
}));

export interface GoogleMapProps
  extends Omit<MapProps, 'center'>,
    PropsWithChildren {
  center?: google.maps.LatLngLiteral;
  markers?: IMarker[];
}

export const GoogleMap: FC<GoogleMapProps> = (props) => {
  const { markers, center, zoom, children, ...rest } = props;
  const [cameraProps, setCameraProps] =
    useState<MapCameraProps>(defaultGoogleMap);
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);

  useEffect(() => {
    const newZoom = zoom || defaultGoogleMap.zoom;
    const newCenter = center || defaultGoogleMap.center;
    setCameraProps((v) => ({ ...v, center: newCenter, zoom: newZoom }));
  }, [center, zoom]);

  const handleCameraChange = useCallback((e: MapCameraChangedEvent) => {
    setCameraProps(e.detail);
  }, []);

  const toggleMarkerInfo = (key: string) => {
    if (selectedMarker !== key) setSelectedMarker(key);
    else setSelectedMarker(null);
  };

  return (
    <GoogleMapApiProvider apiKey={googleMapsApiKey}>
      <MapRoot>
        <Map
          gestureHandling="greedy"
          mapId={googleMapId}
          onCameraChanged={handleCameraChange}
          disableDefaultUI
          mapTypeControl
          mapTypeControlOptions={{
            position: ControlPosition.RIGHT_TOP,
          }}
          {...rest}
          {...cameraProps}
        >
          {markers?.map(({ key, ...marker }) => (
            <MapMarker
              key={key}
              showInfo={selectedMarker === key}
              toggleInfo={() => toggleMarkerInfo(key)}
              {...marker}
            />
          ))}
          <ZoomControl />
          {children}
        </Map>
      </MapRoot>
    </GoogleMapApiProvider>
  );
};
