import { FC, useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { AvailableResources, UnitApi } from '@/api';
import { LocalStorageItems } from '@/constants';
import {
  IEquipment,
  ILineLayer,
  IPointLayer,
  IPolygonLayer,
  IResource,
  IUnitType,
  IVehicle,
  ResourceType,
} from '@/models';
import { useToastStore } from '@/store';

import { RenderFormField } from '../../../FormElements/RenderFormFields';
import { UnitResourceFormFields } from '../data';

interface UnitResourceFormProps {
  cfsId?: string;
  agencyId?: string;
  withUnitOnly?: boolean;
  currentUnitType?: IUnitType;
}

export const UnitResourceForm: FC<UnitResourceFormProps> = ({
  cfsId,
  agencyId,
  currentUnitType,
  withUnitOnly,
}) => {
  const { updateToast } = useToastStore();
  const [resources, setResources] = useState<AvailableResources>({});
  const { vehicles, users, equipments, polygons, points, lines } = resources;

  const employeeSelectItems = useMemo(() => {
    if (!users) return [];
    return users.map(({ _id, fullName, profile }) => {
      let label = '';
      const { badgeNumber, title } = profile?.employmentInformation || {};
      if (badgeNumber) {
        label += badgeNumber;
      }
      if (fullName) {
        label += label ? ` - ${fullName}` : fullName;
      }
      if (title) {
        label += label ? ` - ${title}` : title;
      }
      return {
        label,
        value: _id,
      };
    });
  }, [users]);

  const getSelectItems = (
    itemList:
      | IEquipment[]
      | IPolygonLayer[]
      | ILineLayer[]
      | IPointLayer[]
      | IVehicle[]
      | undefined,
  ) => {
    if (!itemList) return [];
    return itemList.map(({ _id, name }) => ({ label: name, value: _id }));
  };

  useEffect(() => {
    if (currentUnitType) {
      fetchResources();
    }
  }, [currentUnitType]);

  const fetchResources = async () => {
    try {
      if (agencyId) {
        localStorage.setItem(LocalStorageItems.CurrentAgencyId, agencyId);
      }
      const res = await UnitApi.getAvailableResources(
        currentUnitType?._id,
        withUnitOnly,
      );
      setResources(res.data);
      if (cfsId) {
        localStorage.setItem(LocalStorageItems.CurrentAgencyId, '');
      }
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const getOptions = (resourceType: ResourceType) => {
    switch (resourceType) {
      case ResourceType.VEHICLE:
        return getSelectItems(vehicles);
      case ResourceType.PEOPLE:
        return employeeSelectItems;
      case ResourceType.EQUIPMENT:
        return getSelectItems(equipments);
      case ResourceType.POLYGON_LAYER:
        return getSelectItems(polygons);
      case ResourceType.POINT_LAYER:
        return getSelectItems(points);
      case ResourceType.LINE_LAYER:
        return getSelectItems(lines);
      default:
        return [];
    }
  };

  const validator = (
    value: string[],
    resource: IResource,
    errorLabel: string,
  ) => {
    const { min, max, type } = resource;
    if (withUnitOnly) return true;
    const filteredValue = value?.filter((item) => {
      const options = getOptions(type);
      return options.find((option) => option.value === item);
    });
    if (!filteredValue || filteredValue.length < min) {
      if (max) {
        return `You must assign between ${min} and ${max} ${errorLabel}s to this unit`;
      }
      return min > 1
        ? `You must assign at least ${min} ${errorLabel}s to this unit`
        : `You must assign at least ${min} ${errorLabel} to this unit`;
    }
    if (max && filteredValue.length > max) {
      return `You must assign between ${min} and ${max} ${errorLabel}s to this unit`;
    }
    return true;
  };

  const filteredTypeResources = useMemo(() => {
    const typeResources = currentUnitType?.resources;
    if (!typeResources) return [];
    if (!withUnitOnly) return typeResources;
    return typeResources.filter((item) => item.withUnit);
  }, [currentUnitType, withUnitOnly]);

  return (
    <>
      {filteredTypeResources.map((resourceField) => {
        const formField = UnitResourceFormFields[resourceField.type];
        if (!formField) return null;
        return (
          <Grid key={formField.name} xs={12} {...formField.grid} item>
            <RenderFormField
              {...formField}
              items={getOptions(resourceField.type)}
              rules={{
                validate: (value) =>
                  validator(value, resourceField, formField.errorLabel),
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};
