import { useState } from 'react';

import { Button } from '@mui/material';

import { MasterNameApi } from '@/api';
import { MasterNameAutocomplete, NoResultItem } from '@/components';
import { ICFSMasterName, IMasterNamePerson, MasterNameType } from '@/models';
import { getAddressLocation } from '@/services';
import { useToastStore } from '@/store';

import { NewNameModal } from './NewNameModal';
import { ReporterItem } from './ReporterItem';

export interface PrimaryReporterFormProps {
  masterName: IMasterNamePerson | undefined;
  excludedMasterNames?: ICFSMasterName[];
  onChange: (newMasterName?: IMasterNamePerson) => Promise<void>;
}

export const PrimaryReporterForm = ({
  masterName,
  excludedMasterNames,
  onChange,
}: PrimaryReporterFormProps) => {
  const { updateToast } = useToastStore();
  const [openMasterNameModal, setOpenMasterNameModal] =
    useState<boolean>(false);
  const address = masterName?.address;

  const handleKeyUp = async (e: any) => {
    const searchText = e.target.value;
    if (e.key === 'Enter' && searchText) {
      const searchTextSplit = searchText.split(' ');
      const firstName =
        searchTextSplit.length === 1
          ? searchText
          : searchTextSplit.slice(0, -1).join(' ');
      const lastName =
        searchTextSplit.length === 1
          ? null
          : searchTextSplit[searchTextSplit.length - 1];
      try {
        const res = await MasterNameApi.createMasterNamePerson({
          firstName,
          lastName,
        });
        onChange(res.data);
      } catch (err: any) {
        updateToast({ open: true, message: err.msg });
      }
    }
  };

  return (
    <>
      {!masterName ? (
        <MasterNameAutocomplete
          excludedMasterNames={excludedMasterNames?.map(
            (masterNameItem: ICFSMasterName) => {
              return masterNameItem.masterName as IMasterNamePerson;
            },
          )}
          onChange={(v) => {
            onChange(v as IMasterNamePerson);
          }}
          onKeyUp={handleKeyUp}
          filterByTypes={[MasterNameType.PERSON]}
          noResults={
            <NoResultItem
              flexDirection="row"
              alignItems="center"
              sx={{ ml: -1 }}
            >
              <Button
                onClick={() => setOpenMasterNameModal(true)}
                variant="text"
                color="error"
              >
                + Add new
              </Button>
            </NoResultItem>
          }
        />
      ) : (
        <ReporterItem
          name={`${masterName.firstName} ${masterName.lastName || ''}`}
          address={getAddressLocation(address)}
          onEdit={() => setOpenMasterNameModal(true)}
          onRemove={() => onChange()}
        />
      )}
      <NewNameModal
        onSubmitMasterName={onChange}
        isOpen={openMasterNameModal}
        setOpen={setOpenMasterNameModal}
        selectedMasterName={masterName}
      />
    </>
  );
};
