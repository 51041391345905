import { Stack, Typography } from '@mui/material';
import moment from 'moment';

import { IcIncomingCall } from '@/assets/images';
import { ICall } from '@/models';
import { formatPhoneNumber } from '@/utils';

import { CallCardRoot } from '../styles';

interface CallCardProps extends ICall {
  isNewCall?: boolean;
}

const TIME_FORMAT = 'HH:mm';

export const CallCard = ({
  phoneNumber,
  provider,
  startedAt,
  isNewCall,
}: CallCardProps) => {
  return (
    <CallCardRoot className={isNewCall ? 'is-new-call' : ''}>
      <IcIncomingCall className="svg-phone" />
      <Stack>
        <Typography variant="subtitle2" className="call-number">
          {formatPhoneNumber(phoneNumber)}
        </Typography>
        <Stack>
          <Typography className="call-info">
            {provider} {moment(startedAt).format(TIME_FORMAT)}
          </Typography>
        </Stack>
      </Stack>
    </CallCardRoot>
  );
};
