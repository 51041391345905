import { useEffect, useState } from 'react';

import {
  Card,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  ImageListProps,
  styled,
} from '@mui/material';

import defaultImage from '@/assets/images/pngs/image.png';
import { useFileStore, useToastStore } from '@/store';
import colors from '@/theme/variables/colors';

interface FileGridProps extends Omit<ImageListProps, 'children'> {
  images?: string[];
  videos?: string[];
}

const FileGridRoot = styled(ImageList)(() => ({
  width: '100%',

  '& .MuiImageListItem-root': {
    width: '100%',
    borderRadius: 8,

    '& img': {
      width: '100%',
      aspectRatio: 1.5,
      objectFit: 'cover',
      borderRadius: 8,
      border: `1px solid ${colors.grey['20']}`,
    },
  },
}));

export const FileGrid = ({
  images = [],
  videos = [],
  ...rest
}: FileGridProps) => {
  const { sasToken, fetchFileToken, checkTokenExpiration } = useFileStore();
  const { updateToast } = useToastStore();
  const isVideo = videos.length > 0;

  const [tokenFiles, setTokenFiles] = useState<string[]>([]);

  useEffect(() => {
    try {
      const updateTokenFiles = async () => {
        const isExpired = checkTokenExpiration();
        let fileToken;
        if (isExpired || !sasToken) {
          const token = await fetchFileToken();
          fileToken = token.data.sasToken;
        } else {
          fileToken = sasToken;
        }

        const newTokenFiles = videos.length
          ? videos.map((video) => `${video}?${fileToken}`)
          : images.map((image) => `${image}?${fileToken}`);

        setTokenFiles(newTokenFiles);
      };

      updateTokenFiles();
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  }, [images, videos, sasToken]);

  const renderFileType = () => {
    return (
      <FileGridRoot cols={4} gap={16} {...rest}>
        {tokenFiles.map((file: any) =>
          isVideo ? (
            <Grid item xs={12} sm={6} md={4} lg={3} key={file}>
              <Card>
                <CardMedia component="video" src={file} controls />
              </Card>
            </Grid>
          ) : (
            <ImageListItem key={file}>
              <img src={file || defaultImage} alt="" loading="lazy" />
            </ImageListItem>
          ),
        )}
      </FileGridRoot>
    );
  };

  return renderFileType();
};
