import { IcFire, IcMedicalCross, IcPolice } from '@/assets/images';
import { AgencyType } from '@/models';
import colors from '@/theme/variables/colors';

export const requiredUnitFields = [
  {
    name: 'Police',
    agencyType: AgencyType.POLICE,
    icon: IcPolice,
  },
  {
    name: 'Fire',
    agencyType: AgencyType.FIRE,
    icon: IcFire,
  },
  {
    name: 'EMS',
    agencyType: AgencyType.EMS,
    icon: IcMedicalCross,
  },
];

export const requiredUnitFormFields = [
  {
    name: 'unitType',
    type: 'select',
    label: 'Unit type',
    rules: {
      required: 'This field is required.',
    },
    styles: {
      xl: 3,
      sx: {
        mt: 0.25,
        mb: 0.25,
        borderRight: `1px solid ${colors.grey['10']}`,
      },
    },
  },
  {
    name: 'number',
    label: 'Number',
    type: 'integer',
    rules: {
      required: 'This field is required.',
      min: {
        value: 1,
        message: 'Number should be greater than 1.',
      },
    },
    styles: {
      xl: 3,
      sx: {
        mt: 0.25,
        mb: 0.25,
        borderRight: `1px solid ${colors.grey['10']}`,
      },
    },
  },
];
