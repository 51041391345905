import { IFile } from '@/models';

export const fileSizeConverter = (bytes = 0, decimals = 0, onlyMB = false) => {
  const K_UNIT = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (!bytes) return '0B';

  if (onlyMB) return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + 'MB';

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp =
    parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + sizes[i];

  return resp;
};

export const generateFormData = (data: Record<string, any>) => {
  const form_data = new FormData();
  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (let i = 0; i < data[key].length; i++) {
        form_data.append(key, data[key][i]);
      }
    } else if (typeof data[key] === 'object') {
      form_data.append(key, JSON.stringify(data[key]));
    } else {
      form_data.append(key, data[key]);
    }
  }
  return form_data;
};

export const createFileFromUrl = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url.replace(/\?.+/, '').split('/').pop() || '';
  return new File([data], filename, metadata);
};

export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      return resolve(event?.target?.result);
    };
    reader.onerror = (event) => {
      reader.abort();
      return reject(event);
    };
  });
}

export const generateFileLabel = (row: IFile) => {
  const nameCaseInsensitive = row.name.toLowerCase();
  const mimeType = row.mimeType.toLowerCase();

  const videoExtensions = ['.avi', '.mov', '.wmv'];
  const isVideoFile = videoExtensions.some((ext) =>
    nameCaseInsensitive.includes(ext),
  );

  if (mimeType.includes('video') || isVideoFile) {
    return 'video';
  }

  if (mimeType.includes('image')) {
    return 'image';
  }

  return 'document';
};

export const generateFileFromBase64 = (base64String: string) => {
  /**
   * This function should be expanded to generate all kind of files.
   * For now, this function generates only png file.
   */
  const base64Data = base64String.replace(/^data:image\/png;base64,/, '');
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' });
  return new File([blob], 'image.png', { type: 'image/png' });
};
