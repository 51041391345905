import { AxiosResponse } from 'axios';
import { create } from 'zustand';

import { FileToken, StorageApi } from '@/api';

const initialState: FileToken = {};

interface FileStoreState extends FileToken {
  fetchFileToken: () => Promise<AxiosResponse<FileToken>>;
  checkTokenExpiration: () => boolean;
  fetching: boolean;
  error?: string;
}

export const useFileStore = create<FileStoreState>((set, get) => ({
  ...initialState,
  fetching: false,
  checkTokenExpiration: () => {
    const { expiresOn } = get();
    return (
      !expiresOn ||
      new Date(expiresOn).getTime() - (new Date().getTime() + 60000) < 0
    );
  },
  fetchFileToken: async () => {
    const { fetching } = get();

    if (!fetching) {
      try {
        set({ fetching: true });
        const res = await StorageApi.getSasToken();
        set({ fetching: false, ...res.data });
        return res;
      } catch (err: any) {
        set({ fetching: false, error: err.msg });
      }
    }

    return Promise.reject(new Error('Fetching is already in progress'));
  },
}));
