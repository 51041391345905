import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';

import { Stack, Typography, styled } from '@mui/material';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';

import { IcUpload } from '@/assets/images';
import { FilePickerDefaultOptions } from '@/constants';
import { useToastStore } from '@/store';
import { colors } from '@/theme/variables';

import { FileListItem, IFileItem } from './FileListItem';

export type FilePickerDirection = 'vertical' | 'horizontal';

export interface FilePickerProps
  extends Omit<DropzoneOptions, 'onDropAccepted' | 'onDropRejected'> {
  className?: string;
  style?: CSSProperties;
  fileUploadApi?: (...args: any) => Promise<AxiosResponse<IFileItem[]>>;
  apiArgs?: (number | string)[];
  initialFileItems?: IFileItem[];
  onChange: (files: IFileItem[]) => void;
  allowedFileTypes?: string[];
  itemClassName?: string;
  enableEditDescription?: boolean;
  direction?: FilePickerDirection;
}

const FilePickerRoot = styled(Stack)(() => ({
  width: '100%',
  height: 275,
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  '& .pickerBox': {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    border: `1px dashed ${colors.grey['20']}`,
    borderRadius: 8,
    background: colors.lightGrey,
  },

  '& .fileList': {
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start',
    overflow: 'auto',
  },

  '&.vertical-aligned-file-picker': {
    display: 'block',
    height: '100%',
    padding: 0,

    '& .pickerBox': {
      marginRight: 0,
      height: 180,
      marginBottom: '15px',

      '& .title1, .title2': {
        fontWeight: 'bold',
      },
      '& svg': {
        width: 36,
        height: 36,
        marginBottom: '14px',
      },
    },

    '& .fileList': {
      height: 'unset',
      maxHeight: 350,
      overflow: 'auto',
    },
  },
}));

export const FilePicker = (props: FilePickerProps) => {
  const {
    className,
    style,
    fileUploadApi,
    apiArgs = [],
    initialFileItems = [],
    onChange,
    accept,
    itemClassName,
    enableEditDescription,
    direction = 'horizontal',
    ...rest
  } = props;

  const { updateToast } = useToastStore();
  const [files, setFiles] = useState<IFileItem[]>([]);

  useEffect(() => {
    const unloadedFileUrls = initialFileItems.filter(
      (item) => files.findIndex((file) => file.url === item.url) < 0,
    );
    if (unloadedFileUrls.length) {
      const omitOldStyleFiles = initialFileItems.filter((item) => item.name);
      setFiles(omitOldStyleFiles);
      onChange(omitOldStyleFiles);
    }
  }, [initialFileItems]);

  const onDropAccepted = useCallback(
    async (newAcceptedFiles: File[]) => {
      try {
        if (fileUploadApi) {
          const res = await fileUploadApi(...apiArgs, newAcceptedFiles);

          const newFiles = res.data;
          onChange([...files, ...newFiles]);
          setFiles((v) => [...v, ...newFiles]);
        }
      } catch (err: any) {
        updateToast({ open: true, message: err.msg });
      }
    },
    [files],
  );

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      const rejectedFileItems = rejectedFiles.map(({ file, errors }) => ({
        name: file.name,
        url: '',
        size: file.size,
        type: file.type,
        error:
          errors[0]?.code === 'file-too-large'
            ? '​File is too large (maximum 100MB)'
            : errors[0]?.message,
      }));
      onChange([...files, ...rejectedFileItems]);
      setFiles((v) => [...v, ...rejectedFileItems]);
    },
    [files],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      ...(accept || { 'image/jpeg': [], 'image/png': [] }),
    },
    ...FilePickerDefaultOptions,
    ...rest,
    onDropAccepted,
    onDropRejected,
  });

  const handleRemove = (filePath?: string) => {
    const remainFiles = files.filter((file) => file.url !== filePath);
    setFiles(remainFiles);
    onChange(remainFiles);
  };

  return (
    <FilePickerRoot
      justifyContent="center"
      alignItems="center"
      className={clsx(className, {
        'vertical-aligned-file-picker': direction === 'vertical',
      })}
      style={style}
    >
      <Stack {...getRootProps({})} className="pickerBox">
        <input {...getInputProps()} />

        <IcUpload color="red" />

        <Typography className="title1">
          Drop a file here to upload, or
        </Typography>
        <Typography className="title2" color={colors.error.main}>
          click here to browse
        </Typography>
      </Stack>
      <Stack className="fileList">
        {files.map((file) => (
          <FileListItem
            key={file.name}
            item={file}
            enableEditDescription={enableEditDescription}
            className={itemClassName}
            onRemove={handleRemove}
          />
        ))}
      </Stack>
    </FilePickerRoot>
  );
};
