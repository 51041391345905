import {
  IMasterName,
  IMasterNameOrganization,
  IMasterNamePerson,
  MasterNameType,
} from '@/models/master.names';

export const getMasterName = (masterName: IMasterName): string => {
  return masterName.type === MasterNameType.ORGANIZATION
    ? (masterName as IMasterNameOrganization).name
    : (masterName as IMasterNamePerson).fullName ||
        `${masterName.firstName} ${masterName.lastName}`;
};
