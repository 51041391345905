import { FC, useEffect, useRef } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';
import SignaturePad from 'react-signature-pad-wrapper';

import { Box, Button, FormHelperText, InputLabel } from '@mui/material';

import { FormSignatureFieldRoot } from './styles';
import { InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormSignatureFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: string | number) => void;
}

export const FormSignatureField: FC<FormSignatureFieldProps> = (props) => {
  const { name, rules, defaultValue, handleChange, label } = props;
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  const signatureRef = useRef<SignaturePad>(null);

  useEffect(() => {
    if (signatureRef && signatureRef.current) {
      (signatureRef?.current as SignaturePad).instance.addEventListener(
        'endStroke',
        endStrokeListener,
      );
      return () => {
        signatureRef.current?.instance.removeEventListener(
          'endStoke',
          endStrokeListener,
        );
      };
    }
  }, []);

  const endStrokeListener = () => {
    const signatureData = signatureRef.current?.instance.toDataURL();
    setValue(name, signatureData);
    if (handleChange) handleChange(name, signatureData ?? '');
  };

  const clearSignaturePad = () => {
    signatureRef.current?.clear();
    setValue(name, '');
    if (handleChange) handleChange(name, '');
  };

  return (
    <FormSignatureFieldRoot sx={{ mb: 1 }}>
      <InputLabel>{label}</InputLabel>

      <Box className="signature-box">
        <Controller
          control={control}
          defaultValue={defaultValue || ''}
          name={name}
          render={() => <SignaturePad ref={signatureRef} height={150} />}
          rules={rules}
        />
      </Box>

      {!!error && <FormHelperText error>{error.message}</FormHelperText>}
      <Button
        onClick={clearSignaturePad}
        variant="outlined"
        color="error"
        className="btn-clear"
        sx={{ mb: 1 }}
      >
        clear signature
      </Button>
    </FormSignatureFieldRoot>
  );
};
