import { MouseEvent, useEffect, useRef, useState } from 'react';

import { Badge, MenuItem } from '@mui/material';
import { useChannel } from 'ably/react';

import { CfsApi, MasterNameApi, IncomingCallsApi } from '@/api';
import incomingCallAudio from '@/assets/audios/incoming-call.mp3';
import { IcPhone } from '@/assets/images';
import { NarrowButton } from '@/components';
import {
  ICFS,
  IMasterName,
  ICall,
  MasterNameType,
  IMasterNameOrganization,
  IMasterNamePerson,
} from '@/models';
import { playAudio } from '@/services';
import { useToastStore, useUserStore } from '@/store';
import { colors } from '@/theme/variables';
import { formatPhoneNumber } from '@/utils';

import { CallCard } from './components/CallCard';
import { IncomingCallCFSForm } from './components/IncomingCallCFSForm';
import {
  IncomingCallRoot,
  IncomingCallModalRoot,
  IncomingCallMenuRoot,
} from './styles';

const defaultModalValues = {
  open: false,
  title: '',
  phoneNumber: '',
};

export const IncomingCall = () => {
  const { account } = useUserStore();
  const { updateToast } = useToastStore();
  const [modal, setModal] = useState(defaultModalValues);
  const [phoneNumberCounter, setPhoneNumberCounter] = useState<number>();
  const [incomingCalls, setIncomingCalls] = useState<ICall[]>([]);
  const [activeCfses, setActiveCfses] = useState<ICFS[]>([]);
  const [masterNames, setMasterNames] = useState<IMasterNamePerson[]>([]);
  const [menuState, setMenuState] = useState<{ anchorEl: null | HTMLElement }>({
    anchorEl: null,
  });
  const openActionMenu = Boolean(menuState.anchorEl);
  const menuElRef = useRef<HTMLButtonElement>(null);

  useChannel(`account:${account?._id}:incoming-call`, (message) => {
    playAudio(incomingCallAudio);
    const newCall = message.data;
    newCall.isNewCall = true;
    newCall._id = message.extras.headers.incomingCall;

    setIncomingCalls((v) => [newCall, ...v]);
    if (menuElRef.current) menuElRef.current.click();
  });

  useEffect(() => {
    getIncomingCalls();
  }, []);

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ anchorEl: e.currentTarget });
  };

  const handleCloseMenu = () => {
    setMenuState({ anchorEl: null });
  };

  const handleCloseModal = () => {
    setModal(defaultModalValues);
    setMasterNames([]);
  };

  const handleCallItemClick = (item: ICall) => {
    fetchData(item.phoneNumber, item._id);
    setMenuState({ anchorEl: null });
    setModal({
      open: true,
      phoneNumber: item.phoneNumber,
      title: item.phoneNumber,
    });
  };

  const getIncomingCalls = async () => {
    try {
      const params = {
        sort: JSON.stringify({ createdAt: -1 }),
        limit: 1000,
      };
      const res = await IncomingCallsApi.getCalls(params);
      setIncomingCalls(res.data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.msg });
    }
  };

  const fetchData = async (callPhoneNumber: string, callId: string) => {
    const masterNameParams = {
      filter: JSON.stringify({
        phoneNumbers: {
          $elemMatch: {
            phoneNumber: callPhoneNumber,
          },
        },
      }),
      sort: JSON.stringify({ firstName: 1, lastName: 1 }),
    };
    Promise.all([
      CfsApi.activeList({
        sort: JSON.stringify({ createdAt: -1 }),
      }),
      MasterNameApi.getMasterNames(masterNameParams),
      IncomingCallsApi.updateCallReadStatus(callId),
    ])
      .then(([activeCfsRes, masterNamesRes, callStatusRes]) => {
        updateCallStatusCallback(callStatusRes.data.count, callId);
        setActiveCfses(activeCfsRes.data);
        fetchMasterNamesCallback(masterNamesRes.data.results, callPhoneNumber);
      })
      .catch((err) => {
        updateToast({ open: true, message: err.msg });
      });
  };

  const fetchMasterNamesCallback = (
    fetchedMasterNames: IMasterName[],
    callerPhoneNumber: string,
  ) => {
    const isOrganizationCall =
      fetchedMasterNames[0]?.type === MasterNameType.ORGANIZATION;

    if (isOrganizationCall) {
      const { name } = fetchedMasterNames[0] as IMasterNameOrganization;

      const formattedTitle = `${formatPhoneNumber(callerPhoneNumber)}<br/>
        <span
          style="
            font-size: 17px;
            font-weight: 500;
          "
        >
          ${name}
        </span>
      `;

      setModal((v) => ({ ...v, title: formattedTitle }));
    }

    setMasterNames(
      !isOrganizationCall ? (fetchedMasterNames as IMasterNamePerson[]) : [],
    );
  };

  const updateCallStatusCallback = (count: number, callId: string) => {
    const restIncomingCalls = incomingCalls.filter(({ _id }) => _id !== callId);
    setPhoneNumberCounter(count);
    setIncomingCalls(restIncomingCalls);
  };

  return (
    <IncomingCallRoot>
      <NarrowButton
        style={{ backgroundColor: colors.error.main }}
        className="btn-circle"
        onClick={(e) => handleOpenMenu(e)}
        aria-controls={openActionMenu ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openActionMenu ? 'true' : undefined}
        ref={menuElRef}
      >
        <IcPhone />
      </NarrowButton>

      <IncomingCallModalRoot
        open={modal.open}
        title={formatPhoneNumber(modal.title)}
        onClose={() => handleCloseModal()}
      >
        <>
          <Badge badgeContent={phoneNumberCounter} className="badge-counter" />
          <IncomingCallCFSForm
            phoneNumber={modal.phoneNumber}
            masterNames={masterNames}
            activeCfses={activeCfses}
            onClose={handleCloseModal}
          />
        </>
      </IncomingCallModalRoot>

      <IncomingCallMenuRoot
        id="action-menu"
        anchorEl={menuState.anchorEl}
        open={openActionMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {incomingCalls.map(
          (item) =>
            !item.isRead && (
              <MenuItem
                key={item._id}
                sx={{ padding: 0, mb: 1 }}
                onClick={() => handleCallItemClick(item)}
              >
                <CallCard {...item} />
              </MenuItem>
            ),
        )}
      </IncomingCallMenuRoot>
    </IncomingCallRoot>
  );
};
